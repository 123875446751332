import React, { useState } from "react";
import { Card, Tab, Nav } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import VerificationRequestsTable from "./VerificationRequestsTable";
import { useLocation } from "react-router-dom";
import PremiumUserTable from "./PremiumUsersTable";

export default function PremiumUser() {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(
    location?.state?.selectedTab ? location?.state?.selectedTab : "users"
  );

  return (
    <div>
      <PageTitle activeMenu="Premium Users List" motherMenu="Premium User Management" />
      <Card>
        <Card.Body>
          <div className="default-tab">
            <PremiumUserTable />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
