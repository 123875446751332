import React, { useState, useEffect } from "react";
import { Table, Badge, Dropdown } from "react-bootstrap";
import {
  getUserList,
  blockUser,
  deleteUser,
  getPremiumUserList,
} from "../../services/UserServices/UserService";
import Spinner from "../components/Common/Spinner";
import { notifyError, notifyTopRight } from "../components/Common/Toaster";
import Pagination from "../components/Common/Pagination";
import { useLocation, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import moment from "moment";
import dummmyImg from "../../images/profile/images.jpeg";
import { RangeDatePicker } from "@y0c/react-datepicker";

const PremiumUserTable = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const imgBaseURl = process.env.REACT_APP_IMAGE_BASEURL;
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [listLength, setListLength] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [imageForView, setImageForView] = useState("");
  const [view, setView] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    location?.state?.page ? location?.state?.page : 0
  );
  const [search, setSearch] = useState(
    location?.state?.search ? location?.state?.search : ""
  );
  const [initialFetch, setInitialFetch] = useState(true);
  const [gender, setGender] = useState(
    location?.state?.gender ? location?.state?.gender : "all"
  );
  const [type, setType] = useState(
    location?.state?.type ? location?.state?.type : "all"
  );
  const [status, setStatus] = useState(
    location?.state?.status ? location?.state?.status : "all"
  );
  const [isFormFilled, setIsFormFilled] = useState(
    location?.state?.isFormFilled ? location?.state?.isFormFilled : "all"
  );
  const [signUpType, setSignUpType] = useState("all");
  const [exportData, setExportData] = useState(true);
  const [newUsers, setNewUsers] = useState([]);
  const limit = 10;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateRangePickerKey, setDateRangePickerKey] = useState(1);
  function onDateChange(...args) {
    console.log(args, "args");
    setStartDate(moment(args[0]).format("YYYY-MM-DD"));
    {
      args[1]
        ? setEndDate(moment(args[1]).format("YYYY-MM-DD"))
        : setEndDate(null);
    }
  }
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const headers = [
    { label: "CONTACT ID", key: "_id" },
    { label: "EMAIL", key: "email" },
    { label: "FIRST NAME", key: "name" },
    { label: "LAST NAME", key: "" },
    { label: "SMS", key: "phoneNumber" },
  ];
  const getTableData = async () => {
    setLoader(true);
    try {
      const response = await getPremiumUserList(
        currentPage,
        limit,
        type,
        search,
      );
      setUsers(response.data.data.users);
      const total = response.data.data.count;
      setPageCount(Math.ceil(total / limit));
      setListLength(total);
      setLoader(false);
      setInitialFetch(false);
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
    }
  };
  function onReset() {
    // setDateRangePickerKey((prev) => prev + 1);
    setStartDate(null);
    setEndDate(null);
  }
  const onDelete = async (id) => {
    setLoader(true);
    try {
      const response = await deleteUser(id);
      notifyTopRight(response.data?.message);
      getTableData();
      setLoader(false);
    } catch (error) {
      notifyError(error.response?.data?.message);
      console.log(error.response, "error");
      setLoader(false);
    }
  };

  const onAction = async (id) => {
    setLoader(true);
    try {
      const response = await blockUser(id);
      notifyTopRight(response.data?.message);
      getTableData();
      setLoader(false);
    } catch (error) {
      notifyError(error.response?.data?.message);
      console.log(error.response, "error");
      setLoader(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, [currentPage, gender,type, status, isFormFilled, signUpType, endDate]);

  let timeoutId;
  // Define a helper function for debouncing
  const debounce = (func, delay) => {
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  const debouncedHandleFetch = debounce(getTableData, 500);
  useEffect(() => {
    if (initialFetch === false) {
      setCurrentPage(0);
      debouncedHandleFetch();
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, []);

  const getTableData2 = async () => {
    try {
      const response = await getUserList(
        currentPage,
        10000,
        search,
        gender,
        type,
        status,
        isFormFilled,
        signUpType,
        startDate,
        endDate
      );
      // console.log(response.data.data.users,"");

      setNewUsers(response.data.data?.users);
    } catch (error) {
      console.log(error, "error");
    }
  };

  function formatDate(date) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = date.getDate();
    const month = months[date?.getMonth()];
    const year = date.getFullYear();
    
    // Add ordinal suffix (1st, 2nd, 3rd, etc.)
    const ordinal = (n) => {
      const suffix = ["th", "st", "nd", "rd"];
      const remainder = n % 100;
      return n + (suffix[(remainder - 20) % 10] || suffix[remainder] || suffix[0]);
    };
  
    return `${month} ${ordinal(day)} ${year}`;
  }

  const getDuration = (type) => {
    if(type == "monthly"){
      return "1 Month";
    }else if(type == "quarterly"){
      return "3 Months";
    }else if(type == "semi_annually"){
      return "6 Months";
    }else{
      return "12 Months";
    }
  }

  function formatTimestamp(timestamp) {
    const isSeconds = String(timestamp).length === 10;
    const timestampInMilliseconds = isSeconds ? timestamp * 1000 : timestamp;
    return moment(timestampInMilliseconds).format('DD-MM-YYYY');
  }

  return loader ? (
    <Spinner />
  ) : (
    <>
      <div className="d-block justify-content-between align-items-center">
        <div className="d-flex justify-content-between align-items-center">
          <div className="col-4" style={{ display: "flex", columnGap: "10px" }}>
            <div
              className="input-group border bg-white input-group-sm"
              style={{ borderRadius: "8px" }}
            >
              <input
                style={{
                  paddingBottom: "25px",
                  paddingTop: "25px",
                  borderRadius: "10px",
                  fontSize: "14px",
                }}
                type="text"
                name="table_search"
                className="form-control float-right border-0"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              {/* <div className="input-group-append">
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={getTableData}
                >
                  <i className="fa fa-search" />
                </button>
              </div> */}
            </div>
            <button className="btn btn-primary" onClick={getTableData}>
              <i className="fa fa-search" />
            </button>
          </div>

          <div className="">
            <CSVLink
              filename={"Users-List.csv"}
              data={newUsers}
              headers={headers}
            >
              <button className="btn btn-primary">
                Export <i class="flaticon-381-send"></i>
              </button>
            </CSVLink>
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center gap-3 mt-2">
          {" "}
          <div className="mb-2 mb-md-0 col-4">
            <label className="text-black font-w600 fs-14">
              Filter by Duration
            </label>

            <select
              className="form-control"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option value={"all"}>All</option>
              <option value={"monthly"}>1 Month</option>
              <option value={"quarterly"}>3 Months</option>
              <option value={"semi_annually"}>6 Months</option>
            </select>
          </div>
        </div>

      </div>
      <Table responsive>
        <thead style={{ color: "black" }}>
          <tr>
            <th>
              <strong>Image</strong>
            </th>
            <th>
              <strong>NAME</strong>
            </th>
            <th>
              <strong>EMAIL</strong>
            </th>
            <th>
              <strong>PHONE NUMBER</strong>
            </th>
            <th>
              <strong>Joined Date</strong>
            </th>
            <th>
              <strong>Expiry Date</strong>
            </th>
            <th>
              <strong>Duration</strong>
            </th>
            <th>
              <strong>Device</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          {users?.map((item, i) => (
            <tr
              key={i}
              onClick={() =>
                navigate("/premium-user-details", {
                  state: {
                    data: item?._id,
                    selectedTab: "users",
                    search: search,
                    page: currentPage,
                  },
                })
              }
              className="pointer"
            >
              <td>
                <img
                  src={
                    item?.images[0]?.url
                      ? imgBaseURl + item?.images[0]?.url
                      : dummmyImg
                  }
                  alt="img"
                  width={100}
                  height={80}
                  className="rounded"
                  onClick={() => {
                    setImageForView(imgBaseURl + item?.images[0]?.url);
                    setView(true);
                  }}
                />
              </td>
              <td>{item?.name}</td>
              <td>{item?.email}</td>
              <td>
                {item.countryCode && "+" + item.countryCode} {item?.phoneNumber}
              </td>
        
              <td>{item?.subscription?.purchaseDate ? formatTimestamp(item?.subscription?.purchaseDate): ""}</td>
              <td>{ formatTimestamp(item?.subscription?.expireDate)}</td>
              <td>{item?.subscription?.subscriptionPeriod ? getDuration(item?.subscription?.subscriptionPeriod) : ""}</td>
              <td>{item?.subscription?.deviceType ? item?.subscription?.deviceType.toUpperCase() : ""}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      {users?.length === 0 && !loader && (
        <div className="justify-content-center d-flex my-5">
          Sorry, Data Not Found!
        </div>
      )}
      {users?.length !== 0 && (
        <div className="card-footer clearfix">
          <div className="d-block d-sm-flex justify-content-between align-items-center">
            <div className="dataTables_info">
              Showing {currentPage * limit + 1} to{" "}
              {listLength > (currentPage + 1) * limit
                ? (currentPage + 1) * limit
                : listLength}{" "}
              of {listLength} entries
            </div>
            <Pagination
              pageCount={pageCount}
              pageValue={currentPage}
              setPage={setCurrentPage}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PremiumUserTable;
